import React from 'react'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { UseBreakpointExample } from '../../examples/react/UseBreakpointExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="useBreakpoint">
    <Playground example={UseBreakpointExample} />
  </Content>
)

export default Page
