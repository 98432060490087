import React, { useEffect, useState } from 'react'

import {
  Paragraph,
  TaskList,
  TaskListItem,
  useBreakpoint
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const UseBreakpointExample = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  )

  const handleResize = () =>
    setWidth(typeof window !== 'undefined' ? window.innerWidth : 0)

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isSm = useBreakpoint('sm')
  const isMd = useBreakpoint('md')
  const isLg = useBreakpoint('lg')
  const isXl = useBreakpoint('xl')

  return (
    <>
      <Paragraph>Selaimen leveys: {width}</Paragraph>
      <TaskList>
        <TaskListItem status="complete">
          Selaimen leveys on <Code>xs</Code> tai isompi
        </TaskListItem>
        <TaskListItem status={isSm ? 'complete' : undefined}>
          Selaimen leveys <Code>sm</Code> tai isompi
        </TaskListItem>
        <TaskListItem status={isMd ? 'complete' : undefined}>
          Selaimen leveys <Code>md</Code> tai isompi
        </TaskListItem>
        <TaskListItem status={isLg ? 'complete' : undefined}>
          Selaimen leveys <Code>lg</Code> tai isompi
        </TaskListItem>
        <TaskListItem status={isXl ? 'complete' : undefined}>
          Selaimen leveys <Code>xl</Code> tai isompi
        </TaskListItem>
        <TaskListItem status={isSm && !isMd ? 'complete' : undefined}>
          Selaimen leveys on isompi kuin <Code>xs</Code> mutta ei isompi kuin{' '}
          <Code>sm</Code>
        </TaskListItem>
      </TaskList>
    </>
  )
}

export { UseBreakpointExample }
